import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Button } from "antd";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

import { Video } from "../../components/Media";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import seoData from "../../components/SEO/data";
import Pages from "../../components/Pages";
import { removeEmptyKeys, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query mediaVideo($pagePath: String!) {
		hasura {
			...VideoLibrary
			...PageSettings
		}
	}
`;

const FETCH_QUERY = gql`
	query FetchData( $limit: Int, $offset: Int) {
		media_videos(limit: $limit, offset: $offset) {
			title_full
			src
			main_image
			slug
			id
		}
	}
`;

export default function VideosPage({ data }) {
	const { theme } = useThemeContext();
	const hasuraData = get(data, "hasura", {});

	const url = "videos";
	const limit = 10;

	const mediaVideos = get(hasuraData, "media_videos", []);
	const [videoLibrary, setVideoLibrary] = useState(mediaVideos);
	const [offset, setOffset] = useState(limit);
	const seo = get(data, "hasura.page_settings[0]", {});

	const [loadVideos, { called, loading, error, data: videosData }] = useLazyQuery(
		FETCH_QUERY,
		{
			variables: {
				limit,
			},
			fetchPolicy: "cache-and-network",
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setVideoLibrary([...videoLibrary, ...get(videosData, "media_videos", [])]);
			setOffset(get(videosData, "media_videos", []).length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading]);

	return (
		<Pages entity={seo || get(seoData, "videos_page", {})} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Видеотека"}
						pageContext={
							{
								item: {
									title_full: "Медиатека",
									slug: "media",
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock mount={videoLibrary && videoLibrary.length} key={"main-content"}>
				<div className={"container"}>
					<div className="row">
						<TextBlock title={"Видеотека"} className={"pb-0"} />
					</div>
					<div className={"row"}>
						{videoLibrary.map(({ title_full, src, slug }, idx) => (
							<Video
								key={`video-libraryy-item-${idx}`}
								videoSrcURL={src}
								slug={slug}
								videoTitle={title_full}
								className={"col-12 col-md-6"}
							/>
						))}
					</div>
					<div className="row justify-content-center mb-5 d-noscript-none">
						<Button
							disabled={loading || !offset}
							loading={loading}
							onClick={() => loadVideos({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<Share url={url} pageTitleShort={"Видеотека"} />
		</Pages>
	);
}

VideosPage.propTypes = {
	data: PropTypes.object,
};
